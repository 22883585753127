<template>
  <el-card class="box-card">
    <div slot="header"
         class="clearfix">
      <span class="title">经纪人余额</span>
    </div>
    <div>
      <el-alert title="提现金额为100倍数，并且不能大于100000"
                type="warning"
                show-icon></el-alert>
      <el-row style="margin-top:10px"
              :gutter="20">
        <el-col :span="8"
                v-for="item in earningList"
                :key="item.text">
          <el-card @click.native="cardClick(item)"
                   :shadow="item.shadow"
                   v-loading="loading"
                   style="height:180px">
            <h6>{{item.text}}</h6>
            <p class="money">
              <span>{{item.num}}</span>元
            </p>
            <p class="charge">
              <span>{{item.chargeTxt}}</span>
              <span>&nbsp;{{item.charge}}%</span>
            </p>
          </el-card>
        </el-col>
      </el-row>
      <p class="account">支付宝账户：{{alipay_account}}({{name}})</p>
      <div class="withdraw-wrap">
        <div class="money-wrap">
          <span>提现金额：</span>
          <el-input-number v-model="amount"
                           :controls="false"
                           @change="handleChange"
                           placeholder="请输入金额"
                           :step="100"
                           :step-strictly="true"
                           :min="0"
                           :max="100000"></el-input-number>
          <span class="yuan">元</span>
        </div>
        <span class="reality">实际到账金额：{{reality_money}}元</span>
      </div>
      <el-row style="margin-top:35px">
        <el-button @click="submitClick"
                   type="primary">提交</el-button>
      </el-row>
    </div>
  </el-card>
</template>

<script>
import api from "@/api/index";
import { mapState } from "vuex";
export default {
  data () {
    return {
      reality_money: 0,
      amount: 0,
      alipay_account: "",
      name: "",
      loading: true,
      earningList: [
        {
          text: "本月收益余额",
          num: 0,
          chargeTxt: "提现本月收益余额手续费",
          charge: 5,
          type: 0,
          shadow: "always",
        },
        {
          text: "上月收益余额",
          num: 0,
          chargeTxt: "提现上月收益余额手续费",
          charge: 5,
          type: 1,
          shadow: "hover",
        },
        {
          text: "钱包余额",
          num: 0,
          chargeTxt: "税率",
          charge: 5,
          type: 2,
          shadow: "hover",
        },
      ],
      type: 0,
      tax_rate: 0,
      history: 0,
      cost: 0,
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
  },
  created () {
    this.$store.commit("layouts/setPathList", [
      { name: "提现管理", path: "/finance/withdraw" },
      { name: "提现" },
    ]);
    this.$store.commit("layouts/changeBreadcrumb", true);
    this._initState();
    this.alipay_account = this.$route.params.alipay_account;
    this.name = this.$route.params.name;
  },
  beforeDestroy () {
    this.$store.commit("layouts/changeBreadcrumb", false);
  },
  methods: {
    async _initState () {
      const {
        status_code,
        message: { broker, handing_cost, tax_rate },
      } = await api.withdrawCreateApi();
      if (status_code === 200) {
        this.$set(this.earningList[0], "num", broker.coins);
        this.$set(this.earningList[0], "charge", handing_cost.coins);
        this.$set(this.earningList[1], "num", broker.diamond);
        this.$set(this.earningList[1], "charge", handing_cost.diamond);
        this.$set(this.earningList[2], "num", broker.history);
        this.$set(this.earningList[2], "charge", tax_rate);
        this.tax_rate = tax_rate;
        this.history = handing_cost.history;
        this.loading = false;
      }
    },
    cardClick (v) {
      this.earningList.forEach((item) => {
        if (item.type === v.type) {
          item.shadow = "always";
          this.type = item.type;
          this.cost = item.charge;
        } else {
          item.shadow = "hover";
        }
      });
      this.handleChange(this.amount);
    },
    handleChange (val) {
      if (!this.type && typeof this.type === "object") {
        this.$message({
          type: "error",
          message: "请选择提现类型",
        });
        setTimeout(() => {
          this.amount = 0;
        }, 500);
        return;
      }
      let cost = "";
      if (this.type !== 2) {
        cost = this.cost;
      } else {
        cost = this.history;
      }
      this.reality_money = (
        val *
        (1 - cost / 100) *
        (1 - this.tax_rate / 100)
      ).toFixed(2);
    },
    async submitClick () {
      if (!(this.userInfo.role === "personal")) {
        if (!this.type && typeof this.type === "object") {
          this.$message({
            type: "error",
            message: "请选择提现类型",
          });
        } else {
          let params = { amount: this.amount, type: this.type };
          const { status_code, message } = await api.withdrawStoreApi(params);
          if (status_code === 200) {
            this._initState();
            this.$message({
              type: "success",
              message,
            });
          } else {
            if (typeof message === 'object') {
              Object.keys(message).forEach((item) => {
                this.$message.error({ message: message[item] });
              });
            } else {
              this.$message.error({ message });
            }
          }
        }
      } else {
        this.$message.warning("您还未实名认证，请认证后再试")
      }
    },
  },
};
</script>

<style lang="less" scoped>
.box-card /deep/ .el-alert--warning.is-light {
  background-color: rgba(80, 157, 237, 0.1);
  color: #3278eb;
}
h6,
.money,
.charge {
  font-size: 14px;
}
.money {
  margin-top: 20px;
  span {
    font-size: 28px;
  }
}
h6 {
  margin-top: 12px;
}
.charge {
  margin-bottom: 12px;
  margin-top: 40px;
}
.box-card /deep/ .el-card.is-always-shadow,
.el-card.is-hover-shadow:focus,
.el-card.is-hover-shadow:hover {
  color: #3278eb;
  cursor: pointer;
  border: 1px solid #3278eb;
}
.account {
  color: #666666;
  font-size: 14px;
  margin-top: 32px;
}
.money-wrap,
.reality {
  display: flex;
  color: #333333;
  font-size: 14px;
  line-height: 34px;
}
.reality {
  color: #3278eb;
  line-height: 34px;
  margin-left: 20px;
}
.box-card /deep/ .el-input__inner {
  height: 34px;
}
.withdraw-wrap {
  margin-top: 34px;
  display: flex;
}
.yuan {
  margin-left: 10px;
}
.box-card /deep/ .el-alert {
  padding: 13.5px 16px;
}
.box-card /deep/ .el-alert__closebtn {
  top: 18px;
}
.box-card /deep/ .el-input-number .el-input__inner {
  text-align: left;
}
</style>